import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
} from "react-router-dom";
import Homepage from "./features/homepage/Homepage";

function App() {
    return (
        <Router>
            <div className="App">
                <header className="App-header">
                    <ul className={`menu`}>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/blogs">Blogs</Link>
                        </li>
                    </ul>
                    <hr className={`headerline`}/>
                </header>
                <Switch>
                    <Route exact path="/" component={ Homepage } />
                </Switch>
            </div>
        </Router>
    );
}

export default App;
