import React, { useEffect, useState } from 'react';
import logo from "../images/alkemize_1.png";
import config from './../../config';

const Homepage = () => {
    const [ subscribers, setSubscribers] = useState([]);
    useEffect(() => {
        fetch(
            `${config.API}/.netlify/functions/subscribersgql`,
            {
                method: "GET",
            }
        )
            .then(res => res.json())
            .then(response => {
                setSubscribers(response.data);
            })
            .catch(error => console.log(error));
    }, []);
    return (<div>
        <div className={`skeleton`}>
            <img src={logo} className="App-logo" alt="logo" />
            <p>
                Our purpose is to inspire and empower Digital Transformation, Data Governance  leaders to prevent and heal
                burnout, so that they can thrive, create and innovate to their maximum potential. And in turn, do that for
                Digital Transformation Teams, and Organizations alike!
            </p>
            <div style={{ display: 'none'}}>
                <h3>Thank you to our subscribers!</h3>
                {
                    subscribers.map(s => <div key={s._id}>
                        <span>{ s.user }</span>&nbsp;
                        <span>{ s.email }</span>
                    </div>)
                }
            </div>
        </div>
    </div>)
};
export default Homepage;